const config = {
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  selfUrl: process.env.NEXT_PUBLIC_SELF_URL,
  brand: process.env.NEXT_PUBLIC_BRAND,
  brandName: process.env.NEXT_PUBLIC_BRAND_NAME,
  brandURL: process.env.NEXT_PUBLIC_BRAND_URL,
  GTM: process.env.NEXT_PUBLIC_GTM,
  GA4: process.env.NEXT_PUBLIC_GA4,
  env: process.env.NODE_ENV,
  sentry: process.env.NEXT_PUBLIC_SENTRY,
  sentryProject: process.env.NEXT_PUBLIC_SENTRY_PROJECT,
  sentryOrg: process.env.NEXT_PUBLIC_SENTRY_ORG,
};

export default config;
